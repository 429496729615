body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  background-color: #f5f5f5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

:root {
  --white_10: rgba(255, 255, 255, 0.10);
  --white_17: rgba(255, 255, 255, 0.17);
  --white: #ffffff;
  --white_almost: rgba(255, 255, 255, 0.85);
  --white_half: rgba(255, 255, 255, 0.50);
  --white_halfRigid: #7f7f7f;
  --white_purple: #B9B7D6;

  --black_04: rgba(0, 0, 0, 0.04);
  --black_17: rgba(0, 0, 0, 0.17);
  --black_35: rgba(0, 0, 0, 0.35);
  --black: #000000;
  --black_almost: rgba(0, 0, 0, 0.85);
  --black_almostRigid: rgba(56, 56, 56, 0.85);
  --black_half: rgba(0, 0, 0, 0.50);

  --primary_07: rgba(108, 99, 255, 0.07);
  --primary_17: rgba(108, 99, 255, 0.17);
  --primary_35: rgba(108, 99, 255, 0.35);
  --primary: #6c63ff;
  --primary_almostRigid: #8780fd;
  --primary_17_rigid: #e6e4ff;
  --primary_quarter: rgba(108, 99, 255, 0.25);
  --primary_half: rgba(108, 99, 255, 0.50);

  --tag_blue_17: rgba(48, 118, 255, 0.17);
  --tag_blue: #3076ff;
  --tag_orange_17: rgba(255, 149, 0, 0.17);
  --tag_orange: #ff9500;
  --tag_red_17: rgba(255, 59, 48, 0.17);
  --tag_red: #ff3b30;

  --logo_web_17: rgba(16, 87, 126, 0.17);
  --logo_web: #00719c;
  --logo_linkedin: #2867b2;
  --logo_linkedin_half: rgba(40, 103, 178, 0.50);
  --logo_instagram: #f00073;
  --logo_phone: #0e8013;
  --logo_whatsapp_17: rgba(37, 211, 102, 0.17);
  --logo_whatsapp: #25d366;
  --logo_telegram_17: rgba(0, 136, 204, 0.17);
  --logo_telegram: #0088cc;
  --logo_others_17: rgba(128, 128, 128, 0.17);
  --logo_others: #2d2d2d;
  --logo_iban_green: #25d366;

  --nonColor: #d9d9d9;

  --background_colorful: #f5f5f5;
  --background_white: #ffffff;

  --google_green: #34a853;
  --google_blue: #4285f4;
  --google_red: #ea4335;
  --google_orange: #fbbc05;

  --appBar_text_def: #9db2ce;
  --appBar_text_clicked: #386bf6;

  --temporary_action_blue: #007aff;

  --turkRed: #e31d1c;

  --letter_spacing_10: -0.17px;
  --letter_spacing_11: -0.41px;

  --black_20: rgba(0, 0, 0, 0.20);

  --red_05: rgba(255, 99, 99, 0.05);
  --red_delete: rgba(255, 99, 99, 0.70);

  --verified_blue: #3858AA;
  --verified_green: #3AAA38;

  --tag_blue_dark_bg: #d4e9ff;
  --tag_blue_dark_main: #007aff;
  --tag_blue_dark_text: #003d7f;
  --tag_blue_dark_border: rgba(0, 122, 255, 0.17);

  --tag_blue_light_bg: #dff8fd;
  --tag_blue_light_main: #42d4f4;
  --tag_blue_light_text: #216a7a;
  --tag_blue_light_border: rgba(66, 212, 244, 0.17);

  --tag_green_dark_bg: #ddf6e3;
  --tag_green_dark_main: #34c759;
  --tag_green_dark_text: #1a632c;
  --tag_green_dark_border: rgba(52, 199, 89, 0.17);

  --tag_green_light_bg: #f1fff5;
  --tag_green_light_main: #aaffc3;
  --tag_green_light_text: #557f61;
  --tag_green_light_border: rgba(170, 255, 195, 0.17);

  --tag_green_tea_bg: #f4fde0;
  --tag_green_tea_main: #bfef45;
  --tag_green_tea_text: #738f29;
  --tag_green_tea_border: rgba(191, 239, 69, 0.17);

  --tag_purple_dark_bg: #ede2f9;
  --tag_purple_dark_main: #9353dc;
  --tag_purple_dark_text: #49296e;
  --tag_purple_dark_border: rgba(147, 83, 220, 0.17);

  --tag_purple_light_bg: #f9ecff;
  --tag_purple_light_main: #da8fff;
  --tag_purple_light_text: #6d477f;
  --tag_purple_light_border: rgba(218, 143, 255, 0.17);

  --tag_red_def_bg: #fad7d7;
  --tag_red_def_main: #e01014;
  --tag_red_def_text: #70080a;
  --tag_red_def_border: rgba(224, 16, 20, 0.17);

  --tag_pink_def_bg: #ffe5ea;
  --tag_pink_def_main: #ff6482;
  --tag_pink_def_text: #993c4e;
  --tag_pink_def_border: rgba(255, 100, 130, 0.17);

  --tag_orange_def_bg: #ffedd4;
  --tag_orange_def_main: #ff9500;
  --tag_orange_def_text: #7f4a00;
  --tag_orange_def_border: rgba(255, 149, 0, 0.17);

  --tag_yellow_def_bg: #fffad8;
  --tag_yellow_def_main: #ffe119;
  --tag_yellow_def_text: #99870f;
  --tag_yellow_def_border: rgba(255, 225, 25, 0.17);

  --tag_brown_dark_bg: #eae0db;
  --tag_brown_dark_main: #81442b;
  --tag_brown_dark_text: #402215;
  --tag_brown_dark_border: rgba(129, 68, 43, 0.17);

  --tag_brown_light_bg: #f3ede6;
  --tag_brown_light_main: #b59469;
  --tag_brown_light_text: #5a4a34;
  --tag_brown_light_border: rgba(181, 148, 105, 0.17);

  --tag_brown_skin_bg: #fff9f2;
  --tag_brown_skin_main: #ffd8b1;
  --tag_brown_skin_text: #7f6c58;
  --tag_brown_skin_border: rgba(255, 216, 177, 0.17);

  --tag_black_def_bg: #d4d4d4;
  --tag_black_def_main: #535353;
  --tag_black_def_text: #292929;
  --tag_black_def_border: rgba(83, 83, 83, 0.17);

  --tag_silver_def_bg: #f1f1f1;
  --tag_silver_def_main: #a9a9a9;
  --tag_silver_def_text: #656565;
  --tag_silver_def_border: rgba(169, 169, 169, 0.17);

  --transparent: transparent;
  --red: #FF6363;
  --success: #00CC99;
  --success_17: rgba(0, 204, 153, 0.17);
  --success_background: #E6FAF5;
  --warning: #F2C94C;
  --warning_17: rgba(242, 201, 76, 0.17);
  --warning_background: #FEFBF2;
  --error: #EB5757;
  --error_17: rgba(235, 87, 87, 0.17);
  --error_background: #FDF3F3;
  --info: #5458F7;
  --info_17: rgba(84, 88, 247, 0.17);
  --info_background: #F3F3FE;
}


/* Raleway Font Tanımları
@font-face {
  font-family: 'Raleway';
  src: url('/public/assets/fonts/Raleway-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('/public/assets/fonts/Raleway-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('/public/assets/fonts/Raleway-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
} */

/* WorkSans Font Tanımları */
/* @font-face {
  font-family: 'WorkSans';
  src: url('/public/assets/fonts/WorkSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'WorkSans';
  src: url('/public/assets/fonts/WorkSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'WorkSans';
  src: url('/public/assets/fonts/WorkSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'WorkSans';
  src: url('/public/assets/fonts/WorkSans-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
} */