/* Container styles */
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Raleway", sans-serif;
}

/* Content styles */
.content {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  border-radius: 8px;
  width: 480px;
  margin: 0 32px;
  margin-bottom: 64px;
}

.image {
  width: 80%;
}

/* Button styles */
.button {
  width: 80%;
  font-size: 16px;
  background-color: var(--primary);
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bolder;
  height: 48px;
  text-decoration: none;
}

.secondary_button {
  background-color: transparent;
  color: var(--black_almostRigid);
}

.title {
  font-size: 2rem;
  color: var(--black);
  font-weight: bolder;
  margin-top: 40px; 
  margin-bottom: 20px; 
}

/* Message styles */
.message {
  font-size: 1rem;
  color: var(--black_almost);
  margin: 0;
  margin-bottom: 20px;
}